<template>
  <v-container>
    <v-row no-gutters>
      <v-col sm="10" offset-sm="1" md="4" offset-md="4" class="pad-top">
        <v-card flat rounded="xl" class="mx-auto cardColor" max-width="344">
          <v-card-text class="text-center">
            <div class="pt-5 pb-2">
              <v-avatar size="160">
                <img :src="`${publicPath}avatar.JPG`" alt="Annie Zárate" />
              </v-avatar>
            </div>
            <div class="pb-4">
              <h1 class="text-h4 text--primary py-4">Annie Zárate</h1>
              <p class="text--primary">Fotógrafa | Diseñadora</p>
            </div>
            <div class="py-2">
              <a
                class="px-1"
                v-for="(social, i) in socials"
                :key="i"
                :href="social.url"
                target="_blank"
              >
                <img
                  :src="`${publicPath}social/${social.title}${
                    social.hover ? '' : '_'
                  }.png`"
                  :alt="social.title"
                  @mouseover="social.hover = true"
                  @mouseout="social.hover = false"
                  width="30"
                />
              </a>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
      socials: [
        {
          title: "Instagram",
          url: "https://www.instagram.com/anniezarate",
          hover: false,
        },
        {
          title: "Tumblr",
          url: "https://www.tumblr.com/anniezaratemx",
          hover: false,
        },
        {
          title: "Linkedin",
          url: "https://mx.linkedin.com/in/anniezaratemx",
          hover: false,
        },
        {
          title: "Facebook",
          url: "https://www.facebook.com/profile.php?id=100083312593773",
          hover: false,
        },
        {
          title: "Twitter",
          url: "https://twitter.com/anniezaratemx",
          hover: false,
        },
        {
          title: "Email",
          url: "mailto:contacto@anniezarate.com",
          hover: false,
        },
      ],
    };
  },
  mounted() {
    console.log(this.publicPath);
  },
};
</script>

<style>
.pad-top {
  padding-top: 50px !important;
}
.cardColor {
   background-color: rgba(255, 255, 255, 0.9) !important;
   border-color: white !important;
 }
</style>